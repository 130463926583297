import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player'
import {
	isIOS,
	isMobileOnly,
	isBrowser,
	isMobile
  } from "react-device-detect";
  import { Modal } from "react-bootstrap"
const PlayVideo = (props)  => {
	if(props.isOpen) {
		const url = typeof window !== 'undefined' ? window.location.href : '';
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  'event': 'Video Play Btn',
		  'formType': url,
		  'formId': 'Video Play',
		  'formName': 'Video',
		  'formLabel': 'Video'
		}); 
	  }

    return (
        <React.Fragment>
          <Modal
                show={props.isOpen}
                onHide={(e) => props.stopPlay(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen  
                     //  ref={vidRef}
                          // onPlay={trackerVideo(props.title)}
                           url={props.videoId}
                            modestbranding="1"
                              controls={isMobileOnly ? false : true} autoplay={true}
                                 playsinline={true}
                                  playing={props.isOpen}
                                   onEnded={() => { props.stopPlay(false) }}
                                    width='100%' height='100%' />
                 
                    </Modal.Body>
                </Modal>
        </React.Fragment>
    )
};

export default PlayVideo;